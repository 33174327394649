Daily Word: La Palabra Diaria

# Error
Page not found: Página no encontrada

# Login buttons
Sign-in: Iniciar sesión
Sign-out: Desconectar
Subscribe: Suscríbete

# Splash page
Discover: Descubrir
Grow: Crecer
Experience: Experiencia

# Callouts
More from Unity.org: Más de Unity.org

Daily Word Magazine: Revista La Palabra Diaria
The Latest on Unity.org: Noticias recientes de Unity.org
Unity Magazine: Revista Unity

# Focused layout
Back to app: Regresar a la aplicación
Close: Cerrar
Dismiss: Rechazar

E-mail: correo electrónico

# Prayer page
Request a Prayer: Pedir oración en línea ahora

# Pager
Previous: Anterior
Next: Siguiente

# Preferences
Preferences: Preferencias
Login Options: Opciones de acceso
Preferred Language: Idioma preferido
Color Scheme: Opciones de pantalla
Start Page: Página de inicio
Notifications: Notificaciones
Options: Opciones
How to install?: ¿Cómo instalar?

pager:
  previous:
    label: Anterior
  next:
    label: Siguiente

navbar:
  prayer:
    label: Solicita oración
    description: Incluye tus peticiones de oración para el Ministerio de Oración de Unity
  daily-word:
    label: La palabra de hoy
    description: Los suscriptores necesitan iniciar sesión para leer o escuchar los mensajes de La Palabra Diaria
  playlist:
    label: Meditar
    description: Disfruta de los videos más recientes de inspiración y meditación de Unity

theme:
  auto:
    label: Predeterminado
    description: Ajusta los colores según la configuración del sistema
  light:
    label: Claro
    description: El modo claro utiliza un fondo iluminado con texto oscuro
  dark:
    label: Oscuro
    description: El modo oscuro utiliza un fondo opaco con texto más claro

locales:
  en:
    label: Inglés
    description: ''
  es:
    label: Español
    description: ''
